<div class="popup__header">
    Probleem met app melden
</div>

<div class="popup__content">
    <h2 class="mb-2">Probleembeschrijving (optioneel)</h2>
    <textarea class="p-2 w-full border border-neutral-20 rounded" [formControl]="descriptionControl"></textarea>
</div>

<div class="popup__footer">
    <button class="vwui-btn" (click)="close()">
        Annuleren
    </button>
    <button class="vwui-btn is-primary" (click)="reportBug()" [disabled]="loading">
        <span [hidden]="loading">Verzenden</span>
        <app-spinner class="w-5 h-5" [hidden]="!loading"></app-spinner>
    </button>
</div>
