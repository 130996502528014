import {Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, firstValueFrom, Observable, of, Subject} from 'rxjs';
import {AnyProjectJobForm, FORM_END_LOCATION_QUESTION_POSITION, FORM_START_LOCATION_QUESTION_POSITION,} from '../models/project-job-form';
import {debounceTime, filter, map, shareReplay, switchMap, take} from 'rxjs/operators';
import {ProjectJobAnswer} from '../models/project-job-answer';
import {HttpErrorResponse} from '@angular/common/http';
import {FormUtils} from '../utils/form-utils';
import {Question} from '../models/question/question';
import {QuestionTolerance} from '../utils/question-tolerance';
import {temporaryDb} from '../db/temporary-db';
import {Questions} from '../utils/questions';
import {AnyLayeredFormNode} from '../models/layered-form-node';
import {NodeService} from './node.service';
import {Mutable} from '../utils/mutable';

@Injectable({
    providedIn: 'root'
})
export class FormService {
    private static LOCAL_STORAGE_KEY = 'formStore';
    private static LOCAL_STORAGE_MIGRATED_TO_TEMP_INDEXEDDB = 'formStoreMigratedToTempIndexedDb';

    private initializedSubject = new BehaviorSubject(false);
    private ready$ = this.initializedSubject.pipe(
        filter(initialized => initialized),
        take(1),
        shareReplay(1),
    );
    private store$ = new BehaviorSubject<AnyProjectJobForm[]>([]);
    private storeWhenReady$ = this.ready$.pipe(switchMap(() => this.store$.asObservable()));
    private openFormId$ = new BehaviorSubject<number | null>(null);

    canSubmit$ = new BehaviorSubject(false);
    saving$ = new Subject<boolean>();

    submitPending$ = new BehaviorSubject(false);
    triggerSubmitForm$ = new Subject();


    /**
     * Current open form, most recent version from the store
     */
    openForm$: Observable<AnyProjectJobForm | undefined> = combineLatest([this.storeWhenReady$, this.openFormId$]).pipe(
        map(([store, formId]) => store.find(form => form.id === formId)),
        map(form => {
            // Layered forms do not use the currentPosition property
            if (!form || form.type === 'layeredJobForm') {
                return form;
            }

            return {
                ...form,
                currentPosition: FormUtils.determineStartingQuestionPosition(form)
            };
        }),
        shareReplay(1)
    );

    openFormInvalidQuestions$: Observable<Question[]> = this.openForm$.pipe(switchMap(async form => {
        if (!form) {
            return [];
        }

        const invalidQuestions: Question[] = [];
        const questions = FormUtils.isLayeredForm(form)
            ? (await this.nodeService.findNodesByJobFormId(form.id))
                .flatMap(node => FormUtils.questionsForNode(form, node)
                .map((question) => ({node, question})))
            : form.chapters.flatMap(chapter => chapter.questions
                .map((question) => ({node: undefined, question})));

        questions.forEach(({node, question}) => {
            const newestAnswer = FormUtils.getLatestAnswer(form, question.position, node);
            const newestAnswerTolerant = QuestionTolerance.getToleranceMessage(question, newestAnswer?.value || null).tolerant;

            if (question.required
                && FormUtils.isQuestionVisible(form, question.position, node)
                && (!newestAnswer || newestAnswer.value == '' || (newestAnswer.revision < form.answerRevision && !newestAnswerTolerant))
            ) {
                invalidQuestions.push(question);
            }
        });

        return invalidQuestions;
    }));

    locationQuestionValid$ = this.openForm$.pipe(
        filter((form): form is AnyProjectJobForm => !!form),
        map(form => FormUtils.isLocationQuestionValid(form))
    );

    constructor(private nodeService: NodeService) {
        this.storeWhenReady$.pipe(debounceTime(100))
            .subscribe(store => this.saveFormStores(store));
    }


    async initialize() {
        await this.migrateFormToTemporaryIndexedDb();

        this.store$.next(await this.retrieveFormStores());

        this.initializedSubject.next(true);
    }

    async migrateFormToTemporaryIndexedDb() {
        const hasMigrated = localStorage.getItem(FormService.LOCAL_STORAGE_MIGRATED_TO_TEMP_INDEXEDDB) === 'true';
        if (!hasMigrated) {
            const localStorageData: AnyProjectJobForm[] = JSON.parse(localStorage.getItem(FormService.LOCAL_STORAGE_KEY) || '[]') || [];

            await this.saveFormStores(localStorageData);

            localStorage.setItem(FormService.LOCAL_STORAGE_MIGRATED_TO_TEMP_INDEXEDDB, 'true');
        }
    }

    getStoreChanges() {
        return this.storeWhenReady$;
    }

    openForm(id: number) {
        this.openFormId$.next(id);
    }

    async removeFormsById(ids: number[]) {
        if (ids.length === 0) {
            return;
        }
        const store = await firstValueFrom(this.storeWhenReady$);

        this.store$.next(store.filter(form => -1 === ids.indexOf(form.id)));
        await this.nodeService.removeNodesForFormIds(ids);
    }

    async mergeForm(form: AnyProjectJobForm) {
        await this.mergeForms([form]);
    }

    async mergeForms(forms: AnyProjectJobForm[]) {
        const store = await firstValueFrom(this.storeWhenReady$);
        for (const form of forms) {
            const formIndex = store.findIndex(it => it.id === form.id);
            if (formIndex === -1) {
                store.push(form);
            } else {
                store[formIndex] = FormUtils.mergeForm(form, store[formIndex]);
            }

            if (FormUtils.isLayeredForm(form)) {
                await this.nodeService.mergeNodes(form);
            }
        }

        this.store$.next(store);
    }

    async updateOrAddAnswerInStore(answer: ProjectJobAnswer, updateOnly: boolean = false) {
        const store = await firstValueFrom(this.storeWhenReady$);
        const storedJob = store.find(it => it.id === answer.job);

        if (!storedJob) {
            throw new HttpErrorResponse({status: 404, error: `Job #${answer.job} not found`});
        }

        const jobAnswerIndex = storedJob.answers.findIndex(it => {
            return it.node?.id === answer.node?.id && it.position === answer.position && it.revision === answer.revision;
        });

        if (jobAnswerIndex === -1) {
            if (updateOnly) {
                console.error(`Answer for job #${answer.job} not found in store, cannot update`, answer);
            } else {
                storedJob.answers.push(answer);
            }
        } else {
            storedJob.answers[jobAnswerIndex] = answer;
        }

        this.store$.next(store);
    }

    async updateForm(jobId: number, updateHandler: (form: AnyProjectJobForm) => AnyProjectJobForm) {
        const form = await this.storeWhenReady$.pipe(take(1), map(it => it.find(job => job.id === jobId))).toPromise();
        if (!form) {
            throw new Error(`form with jobId ${jobId} not found`);
        }
        const newForm = updateHandler(form);
        if (newForm) {
            await this.mergeForm(newForm);
        }
    }

    async replaceAnswers(syncJob: AnyProjectJobForm) {
        const store = await firstValueFrom(this.storeWhenReady$);
        const job = store.find(it => it.id === syncJob.id);
        if (!job) {
            throw new Error(`Job #${syncJob.id} not found`);
        }

        syncJob.answers.forEach((syncAnswer: ProjectJobAnswer) => {
            const answerIndex = job.answers.findIndex(it => {
                return it.node?.id === syncAnswer.node?.id && it.position === syncAnswer.position && it.revision === syncAnswer.revision;
            });
            if (answerIndex === -1) {
                job.answers.push(syncAnswer);
            } else {
                job.answers[answerIndex] = syncAnswer;
            }
        });

        this.store$.next(store);
    }

    async getJobOrFail(jobId: number) {
        const job = await firstValueFrom(this.getJob(jobId));

        if (!job) {
            throw new Error(`Job #${jobId} not found`);
        }

        return job;
    }

    async getQuestion(jobId: number, position: number, nodeId?: string) {
        const job = await this.getJobOrFail(jobId);
        const node = (nodeId ? await this.nodeService.findNodeById(nodeId) : undefined);

        return FormUtils.getQuestionByPosition(job, position, node);
    }

    getJob(jobId: number): Observable<AnyProjectJobForm | null> {
        return this.storeWhenReady$.pipe(
            map(store => {
                const job = store.find(it => it.id === jobId) || null;
                if (job === null) {
                    console.warn(`Job #${jobId} not found in store`);
                }
                return job
            }),
        );
    }

    getQuestionAsObservable(jobId: number, position: number, node?: AnyLayeredFormNode): Observable<Question | null> {
        if (position === FORM_START_LOCATION_QUESTION_POSITION || position === FORM_END_LOCATION_QUESTION_POSITION) {
            return of(null);
        }

        return this.getJob(jobId).pipe(
            map(it => it !== null ? FormUtils.getQuestionByPosition(it, position, node) : null)
        );
    }

    async getChapterForQuestion(jobId: number, questionPosition: number) {
        if (questionPosition === FORM_START_LOCATION_QUESTION_POSITION || questionPosition === FORM_END_LOCATION_QUESTION_POSITION) {
            return null;
        }

        const position = Questions.getChapterPositionForQuestion(questionPosition);
        const job = await this.getJobOrFail(jobId);
        if (job.type !== 'jobForm' || position === null) {
            throw new Error(`Job #${jobId} is not a job form or chapter for question could not be found`);
        }

        return FormUtils.getChapterByPosition(job, position);
    }

    private async saveFormStores(store: AnyProjectJobForm[]) {
        await temporaryDb.transaction('rw', temporaryDb.projectJobForms, async () => {
            await temporaryDb.projectJobForms.clear();
            await temporaryDb.projectJobForms.bulkAdd(store);
        });
    }

    private async retrieveFormStores(): Promise<AnyProjectJobForm[]> {
        const loadedForms: AnyProjectJobForm[] = await temporaryDb.projectJobForms.toArray();
        for (const loadedForm of loadedForms) {
            // Add type to forms that were saved before the type was introduced
            if (!('type' in loadedForm)) {
                (loadedForm as Mutable<AnyProjectJobForm>).type = 'jobForm';
            }
        }

        return loadedForms;
    }
}
