import {Component} from '@angular/core';
import {FormControl} from '@angular/forms';
import {AppStateUploader} from '../../services/app-insights/app-state-uploader';
import {AppInsightsService} from '../../services/app-insights/app-insights.service';
import {ToastrService} from 'ngx-toastr';
import {AuthenticationService} from '../../services/authentication.service';
import {PopupRef} from '../../helpers/popup-ref';

@Component({
    selector: 'app-report-bug-modal',
    templateUrl: './report-bug-modal.component.html'
})
export class ReportBugModalComponent {
    loading = false;

    descriptionControl = new FormControl('', {nonNullable: true});

    constructor(
        private stateUploader: AppStateUploader,
        private appInsightService: AppInsightsService,
        private toastr: ToastrService,
        private authenticationService: AuthenticationService,
        private popupRef: PopupRef
    ) {
    }

    close() {
        return this.popupRef.close();
    }

    async reportBug() {
        try {
            this.loading = true;
            const stateFileUrl = await this.stateUploader.uploadState();
            this.appInsightService.logEvent('BugReported', {
                stateBlobUrl: stateFileUrl,
                description: this.descriptionControl.value,
                userEmail: this.authenticationService.loggedInEmail$.value,
            });

            await this.toastr.success('Probleem is gemeld');
            await this.popupRef.close();
        } catch (ex) {
            console.error('Failed to report bug', ex);
            await this.toastr.error('Er is iets misgegaan bij het melden van het probleem');
        } finally {
            this.loading = false;
        }
    }
}
